a.social-icon {
  transition: transform 0.2s ease-in-out;
}
a.social-icon:hover {
  transform: translateX(15%);
}

g.social-svg-background {
  /* fill: #b5c8c2 !important; */
  fill: white !important;
}

g.social-svg-mask {
  fill: #b5c8c2 !important;
  /* fill: #247ba0 !important; */
  /* fill: #fdee6c !important; */
}
